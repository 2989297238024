import React from 'react'

import TextInput from './TextInput'

function PriceInput({ value, onChange, placeholder }) {
  const formatBeforeChange = e => {
    const value = e as string
    if (!value || value.length === 0) {
      onChange('')
      return
    }
    const valueClean = value.split(/\s+/).join('')

    if (isNaN(parseInt(valueClean, 10))) {
      return
    }

    const formattedValue = valueClean.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    onChange(formattedValue)
  }

  return (
    <TextInput
      type="text"
      value={value}
      onChange={formatBeforeChange}
      placeholder={placeholder}
      label=""
      required={false}
    />
  )
}

export default PriceInput
