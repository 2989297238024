import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

import SEO from '../components/seo'
import Button from '../components/common/Button'
import '../style/global.scss'
import TextInput from '../components/common/TextInput'
import departementList from '../assets/departements.json'
import Layout from '../components/Layout'
import TextSection from './baremes/TextSection'
import { colours, device } from '../style/variables'
import { navigate } from 'gatsby'
import BannerCard from '../components/common/BannerCard'
import PriceInput from '../components/common/PriceInput'

const FloatingBlock = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  top: -50%;
  right: 0px;
  align-items: center;
  & > * {
    width: 100%;
  }
  & > *:last-child {
    width: fit-content;
  }
  background: white;
  @media ${device.smartphone} {
    position: inherit;
    margin: auto;
    width: 90%;
    margin-bottom: 3rem;
  }
`

const WhiteBorderTextField = styled(TextField)`
  & fieldset {
    border-color: #dfe4ec;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-width: 1px;
      border-color: #dfe4ec;
    }
  }
`

const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-popupIndicator {
    display: none;
  }
  margin-bottom: 17px;

  & .MuiInputBase-root {
    font-size: 14px;
    font-family: 'Poppins';
  }
`

const EstimationBlock = styled.div`
  background: #fff6f5;
  border: 1px solid #ee8888;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const EstimationPrice = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #ff7057;
`

const EstimationLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 10px;
  color: #393939;
`
const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 5px;
  color: #001e3f;
`

const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 57px;
  text-align: center;
  color: #0b315e;
  margin-bottom: 8px;
`

const OrangeSeparator = styled.div`
  width: 58px;
  height: 7px;
  background: #ff7057;
  margin-bottom: 54px;
`

const Separator = styled.div`
  width: 1019px;
  height: 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 40px;
  @media ${device.smartphone} {
    width: 90%;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 120px;
  @media ${device.smartphone} {
    width: 100vw;
    margin: auto;
    margin-top: 30px;
    overflow: hidden;
  }
`
const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 31px;
  color: #001e3f;
  margin-bottom: 8px;
  @media ${device.smartphone} {
    text-align: center;
    margin: auto;
  }
`

const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: #7c8c9f;
`

const Content = styled.div`
  max-width: 1020px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${device.smartphone} {
    align-items: center;
  }
`

const TableContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 80px;
  @media ${device.smartphone} {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
`

const Row = styled.tr<{ last?: boolean }>`
  vertical-align: middle;
  height: ${props => (props.last ? '10px' : 'inherit')};
`

const TitleRow = styled.td<{ isTitle?: boolean; alternate?: boolean }>`
  width: 198px;
  max-width: 198px;
  background-color: ${props => (props.alternate ? 'white' : '#FFF6F5')};
  border-radius: 4px 0px 0px 4px;
  color: ${props => (props.isTitle ? '#FF7057' : 'black')};
  font-weight: ${props => (props.isTitle ? 'bold' : 'normal')};
  font-size: ${props => (props.isTitle ? '12px' : '10px')};
  -webkit-box-shadow: inset -14px 0px 8px -10px rgb(0 0 0 / 10%);
  height: ${props => (props.isTitle ? '60px' : 'inherit')};
  vertical-align: ${props => (props.isTitle ? 'bottom' : 'middle')};
  padding-left: 15px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-right: 30px;
  white-space: ${props => (props.isTitle ? 'nowrap' : 'unset')};

  @media ${device.desktop} {
    font-size: ${props => (props.isTitle ? '12px' : '13px')};
  }
`
function getBoxShadow(first?: boolean, last?: boolean) {
  if (first) {
    return '0px -2px 0px 0px white, 0px -1px 6px 0px rgb(0 0 0 / 10%)'
  } else if (last) {
    return '0px 3px 0px 0px white, 0px 5px 5px 0px rgb(0 0 0 / 10%)'
  } else {
    return 'none'
  }
}
const ParisRow = styled.td<{ alternate?: boolean; first?: boolean; last?: boolean }>`
  width: 218px;
  background-color: ${props => (props.alternate ? '#FFF7F5' : 'white')};
  text-align: center;
  border-bottom: ${props => (props.last ? 'none' : '1px solid #0000000d')};
  vertical-align: middle;
  font-size: 12px;
  font-weight: bold;
  box-shadow: ${props => getBoxShadow(props.first, props.last)};
  position: relative;
  @media ${device.smartphone} {
    border-bottom: none;
  }
`
const ProvinceRow = styled.td<{ alternate?: boolean }>`
  width: 266px;
  background-color: #fff6f5;
  text-align: center;
  border-bottom: 1px solid #0000000d;
  vertical-align: middle;
  -webkit-box-shadow: inset 14px 0px 8px -10px rgb(0 0 0 / 10%);
  font-size: 12px;
  font-weight: bold;
  position: relative;
`

const StyledTable = styled.div`
  background-color: #fff6f5;
  border: 1px solid #ee8888;
  padding-left: 12px;
  border-radius: 20px;
  display: flex;
  @media ${device.smartphone} {
    border-radius: 0px;
    width: 100vw;
    border: none;
    flex-direction: column;
  }
`

const FakeButton = styled.div`
  align-items: center;
  background: #0e47c1;
  border-radius: 38.7127px;
  color: white;
  display: flex;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  height: 31px;
  justify-content: center;
  left: 50%;
  line-height: 15px;
  padding: 7px 0;
  position: absolute;
  text-align: center;
  top: -50px;
  transform: translate(-50%, 0px);
  width: 98px;

  @media ${device.tablet} {
    padding: 9px 0;
    top: -55px;
    width: 135px;
  }
`

const LittleText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #979797;
  margin: 2rem 1rem 2rem 1rem;
`

const MobileTableHeader = styled.div`
  width: 100vw;
  margin-left: -12px;
  height: 165px;
  background: #fff6f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: -120px;
`

interface Departement {
  num_dep: number
  dep_name: string
  region_name: string
}

const IDF_DEPARTMENTS = [75, 77, 78, 91, 92, 93, 94, 95]

function Baremes() {
  const [priceValue, setPriceValue] = useState('0')
  const [inputValue, setInputValue] = useState('')
  const [department, setDepartment] = useState<Departement | null>(null)
  const [honoraires, setHonoraires] = useState(0)

  const computeHonoraires = () => {
    if (!department) {
      return
    }
    const formattedPrice = parseInt(priceValue.split(/\s+/).join(''), 10)
    // If province
    if (!IDF_DEPARTMENTS.includes(department!.num_dep)) {
      if (formattedPrice < 125000) {
        setHonoraires(6000)
      } else if (formattedPrice < 500000) {
        setHonoraires(formattedPrice * 0.048)
      } else {
        setHonoraires(formattedPrice * 0.036)
      }
    } else {
      // if IDF
      if (formattedPrice < 125000) {
        setHonoraires(6000)
      } else if (formattedPrice < 500000) {
        setHonoraires(formattedPrice * 0.042)
      } else {
        setHonoraires(formattedPrice * 0.03)
      }
    }
  }

  useEffect(() => {
    computeHonoraires()
  }, [priceValue, department])

  return (
    <Layout>
      <SEO
        title={`Barèmes`}
        description={`Calculez les honoraires de vente avec Allowa. Un prix juste pour un service haut de gamme.`}
        keywords={`barèmes allowa, tarifs allowa, tarifs vente immobilière, barèmes vente immobilière, calculer honoraires vente, honoraires vente allowa`}
        pathname={`baremes`}
      />
      <Container>
        <Title>Nos services & tarifs</Title>
        <OrangeSeparator />
        <Separator />
        <Content>
          <SubTitle>Calculez les honoraires de votre projet de vente</SubTitle>
          <Description>Un prix juste pour un service haut de gamme</Description>
          <TableContainer>
            <StyledTable>
              <MobileTableHeader className="mobile-only" />
              <table style={{ marginBottom: 0 }}>
                <tbody>
                  <Row>
                    <TitleRow isTitle>Prix du bien</TitleRow>
                    <ParisRow first>
                      <FakeButton>Paris/IDF</FakeButton>
                    </ParisRow>
                    <ProvinceRow>
                      <FakeButton>Province</FakeButton>
                    </ProvinceRow>
                  </Row>
                  <Row>
                    <TitleRow alternate>Moins de 125000€</TitleRow>
                    <ParisRow>6 000 € TTC</ParisRow>
                    <ProvinceRow>6 000 € TTC</ProvinceRow>
                  </Row>
                  <Row>
                    <TitleRow>de 125000€ à 500000€</TitleRow>
                    <ParisRow>4.2% TTC</ParisRow>
                    <ProvinceRow>4.8% TTC</ProvinceRow>
                  </Row>
                  <Row>
                    <TitleRow alternate>Plus de 500000€</TitleRow>
                    <ParisRow>3% TTC</ParisRow>
                    <ProvinceRow>3.6% TTC</ProvinceRow>
                  </Row>
                  <Row last>
                    <TitleRow> </TitleRow>
                    <ParisRow last> </ParisRow>
                    <ProvinceRow> </ProvinceRow>
                  </Row>
                </tbody>
              </table>
            </StyledTable>
            <FloatingBlock>
              <Label>Votre région</Label>
              <StyledAutocomplete
                title="Votre région"
                options={departementList}
                getOptionLabel={option => option.dep_name}
                value={departementList.find(el => el === department)}
                onChange={(e, newValue) => setDepartment(newValue as Departement)}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue)
                }}
                renderInput={params => (
                  <WhiteBorderTextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: 'Recherchez une région',
                      style: {
                        paddingRight: 8,
                        height: 65,
                        borderRadius: 10,
                      },
                    }}
                  />
                )}
                noOptionsText="Pas de résultat"
                openText="Ouvrir"
              />
              <Label>Prix estimé de votre logement</Label>
              <PriceInput
                value={!priceValue || priceValue === '0' ? '' : priceValue}
                onChange={value => setPriceValue(value)}
                placeholder="Prix en euros"
              />
              <EstimationBlock>
                <EstimationLabel>Estimation honoraires Allowa</EstimationLabel>
                <EstimationPrice>
                  {honoraires > 0
                    ? new Intl.NumberFormat('fr-FR', {
                      style: 'currency',
                      currency: 'EUR',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(honoraires) + ' TTC'
                    : '- €'}
                </EstimationPrice>
              </EstimationBlock>
              <Button label="Démarrer mon projet" onClick={() => navigate('/estimation')} orange />
            </FloatingBlock>
          </TableContainer>
          <LittleText>
            Source : Selon Le Figaro, la commission moyenne d'une agence traditionnelle était de
            4.87 % en 2016.
          </LittleText>
        </Content>
      </Container>
      <TextSection />
      <div
        style={{
          margin: '2rem auto 3rem auto',
          maxWidth: 1040,
        }}
      >
        <BannerCard
          title={'Vous avez un projet de vente ?'}
          content={
            "Démarrer votre projet en réalisant votre estimation en ligne. C'est gratuit et sans engagement."
          }
          buttonLabel={'Estimer mon logement'}
          onClick={() => navigate('/estimation')}
          foregroundColor={colours.lightBlue}
          backgroundColor={colours.allowaBlue}
          fontColor="#001E3F"
        />
      </div>
    </Layout>
  )
}

export default Baremes
